import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import moment from "moment";
import Swal from "../Swal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { formatCurrency } from "../../utils/formats";
import ReactPaginate from "react-paginate";
import BusinessContext from "../../context/businessContext";

const AppraisalByAdvisorReport = () => {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [filterType, setFilterType] = useState(4);
  const [selectedDate, setSelectedDate] = useState(null);
  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);

  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  useEffect(() => {
    if (filterType != 5) {
      getReportData({ date_type: filterType }, 1);
    } else if (filterType == 5) {
      getReportData({ date_type: 4 }, 1);
    }
  }, [filterType, businessCtx.selectedBusinessId]);

  const getReportData = async (formData = {}, page) => {
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    setLoading(true);
    await api
      .post(`api/report/appraisal-by-advisor?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setResponseData(res.data.data.appraisalByAdvisor);
          setPagination(res.data.data.paginate);
        } else {
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onRangeChange = (value) => {
    setSelectedDate(value);
    if (value != null) {
      getReportData(
        {
          date_type: filterType,
          start_date: moment(value[0]).format("YYYY-MM-DD"),
          end_date: moment(value[1]).format("YYYY-MM-DD"),
        },
        1
      );
    }
  };

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (filterType != "5") {
      filterData = {
        ...filterData,
        date_type: filterType,
      };
    } else {
      if (selectedDate != null) {
        filterData = {
          ...filterData,
          date_type: "5",
          start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        };
      }
    }

    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        getReportData(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        getReportData(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      getReportData(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (filterType != "5") {
      filterData = {
        ...filterData,
        date_type: filterType,
      };
    } else {
      if (selectedDate != null) {
        filterData = {
          ...filterData,
          date_type: "5",
          start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        };
      }
    }
    getReportData(filterData, e.selected + 1);
  };

  const filterOnChange = (value) => {
    setFilterType(value);
  };

  return (
    <>
      <div className="dashboard-count-filter mb-24">
        <div className="row align-items-center">
          {filterType == 5 && (
            <div className="col-auto pe-0">
              <DateRangePicker
                onChange={(e) => onRangeChange(e)}
                value={selectedDate}
                format="M-d-y"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                calendarIcon={<i className="icon icon-calender"></i>}
                maxDate={new Date()}
                clearIcon={null}
              />
            </div>
          )}
          <div className="col-auto">
            <div className="form-group">
              <select
                className="form-select"
                onChange={(e) => filterOnChange(e.target.value)}
                defaultValue={filterType}>
                <option value="1">This day</option>
                <option value="2">This week</option>
                <option value="3">This month</option>
                <option value="4">This year</option>
                <option value="5">Range</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card p-0">
          <div className="dashboard-latest-apraisals business-table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    Employees{" "}
                    <span
                      className={`table-sort ${
                        sortField == "name" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort("name", sortField, sortType, pagination.current)
                      }></span>
                  </th>
                  <th className="text-center">
                    Total New Appraisal{" "}
                    <span
                      className={`table-sort ${
                        sortField == "totalAppraisal" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "totalAppraisal",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th className="text-center">
                    Total Accepted Appraisals{" "}
                    <span
                      className={`table-sort ${
                        sortField == "totalAcceptedAppraisal" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "totalAcceptedAppraisal",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th className="text-center">
                    Closing Percentage{" "}
                    <span
                      className={`table-sort ${
                        sortField == "closingPercentage" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "closingPercentage",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th className="text-center">
                    Total Commission{" "}
                    <span
                      className={`table-sort ${
                        sortField == "totalCommission" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "totalCommission",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {responseData?.length != 0 ? (
                  responseData?.map((data) => (
                    <tr key={data?.id}>
                      <td>{data?.name}</td>
                      <td className="text-center">{data?.totalAppraisal}</td>
                      <td className="text-center">
                        {data?.totalAcceptedAppraisal}
                      </td>
                      <td className="text-center">
                        {data?.closingPercentage}%
                      </td>
                      <td className="text-center">
                        {formatCurrency(data?.totalCommission)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={6}>
                      {loading ? "Loading..." : "No Data Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            forcePage={parseInt(pagination?.current - 1)}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default AppraisalByAdvisorReport;
