import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import moment from "moment";
import userImg from "../../assets/img/user.jpg";
import vehicleImg from "../../assets/img/vehicle.jpg";
import ThemeContext from "../../context/themeContext";
import Swal from "../Swal";
import { Link } from "react-router-dom";
import routes from "../../constants/routesConstants";
import { formatCurrency } from "../../utils/formats";
import BusinessContext from "../../context/businessContext";

const TopEmployees = ({ filterType, selectedDate }) => {
  const [responseData, setResponsedata] = useState({});
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [byType, setByType] = useState(1);

  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const themeCtx = useContext(ThemeContext);

  useEffect(() => {
    let filterData = {};
    if (filterType != 5) {
      filterData = {
        ...filterData,
        sw_type: filterType,
        field_name: byType,
      };
      getTopEmployeesData(filterData, 1);
    } else if (filterType == 5) {
      filterData = {
        ...filterData,
        sw_type: 4,
        field_name: byType,
      };
      getTopEmployeesData(filterData, 1);
    }
    if (selectedDate != null && filterType == 5) {
      filterData = {
        ...filterData,
        field_name: byType,
        sw_type: filterType,
        start_date_sw: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date_sw: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
      getTopEmployeesData(filterData, 1);
    }
  }, [filterType, byType, businessCtx.selectedBusinessId, selectedDate]);

  const getTopEmployeesData = async (formData = {}) => {
    setLoading(true);
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    await api
      .post("api/dashboard/top-service-writer", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
        } else {
          setResponsedata([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const byTypeChange = (value) => {
    setByType(value);
  };

  const showEmployeeDetail = async (data) => {
    if (data.id != undefined) {
      await api
        .post(
          `api/dashboard/get-top-employees`,
          { user_id: data.id },
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData?.token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setEmployeeDetail({ ...response.data.data, user_id: data.id });
          } else {
            setEmployeeDetail(null);
          }
          themeCtx.showDropShadowHandler();
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 401) {
            authCtx.logout();
          } else {
            Swal.fire(
              "Opps!",
              "Sorry we are not able to complete your request at this time. Please try again later.",
              "error"
            );
          }
        });
    }
  };

  const closeHandler = () => {
    themeCtx.hideDropShadowHandler();
    setEmployeeDetail(null);
  };

  return (
    <>
      <div className="dashboard-card mb-24 mb-xl-0">
        <div className="dashboard-card-filter">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-title">
                Top{" "}
                {authCtx.userData?.user_type != 1
                  ? "Employees"
                  : "Service Advisors"}
              </h4>
            </div>
            <div className="col-auto">
              <div className="dashboard-card-select">
                <div className="form-group">
                  <select
                    className="form-select"
                    onChange={(e) => byTypeChange(e.target.value)}
                    defaultValue={byType}>
                    <option value="1">By Completed</option>
                    <option value="2">By Appraisal</option>
                    <option value="3">By Commission</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-top-employees with-cursor">
          <table className="table">
            <thead>
              <tr>
                {authCtx.userData?.user_type == 3 && <th>Business Name</th>}
                <th>
                  {authCtx.userData?.user_type != 1
                    ? "Employees"
                    : "Service Advisors"}
                </th>
                <th>Appraisals</th>
                <th>Accepted</th>
                <th>Commission</th>
                {/* <th>Submit Bonus</th> */}
              </tr>
            </thead>
            <tbody>
              {responseData?.topServiceWriter &&
              responseData?.topServiceWriter.length != 0 ? (
                responseData?.topServiceWriter.map((data) => (
                  <tr key={data?.id} onClick={() => showEmployeeDetail(data)}>
                    {authCtx.userData?.user_type == 3 && (
                      <td>{data?.businessName}</td>
                    )}
                    <td>
                      <div className="dashboard-top-employees-profile">
                        <img
                          src={data?.image != "" ? data?.image : userImg}
                          alt=""
                        />
                      </div>
                      {data?.name}
                    </td>
                    <td>{data?.totalAppraisal}</td>
                    <td className="total-won">{data?.totalAccepted}</td>
                    <td className="total-amount">
                      {formatCurrency(data?.totalCommission)}
                    </td>
                    {/* <td className="total-amount">
                      {formatCurrency(data?.totalSubmitBonus)}
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr className="text-start text-sm-center">
                  <td colSpan={7}>
                    {loading
                      ? "Loading..."
                      : `No ${
                          authCtx.userData?.user_type != 1
                            ? "Employee"
                            : "Service Advisors"
                        } Found.`}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className={`dashboard-popup ${employeeDetail != null ? "show" : ""}`}>
        <div className="top-employee-popup dashboard-popup-inner">
          <button
            type="button"
            className="dashboard-popup-close"
            onClick={() => closeHandler()}>
            <i className="icon icon-close"></i>
          </button>
          <div className="top-employee-popup-header">
            <div className="row align-items-center">
              <div className="col-md col-12">
                <div className="top-employee-user">
                  <div className="top-employee-user-image">
                    <img
                      src={
                        employeeDetail?.userImage != ""
                          ? employeeDetail?.userImage
                          : userImg
                      }
                      alt=""
                    />
                  </div>
                  <div className="top-employee-user-info">
                    <h2>{employeeDetail?.userName}</h2>
                    <p>
                      {authCtx.userData?.user_type != 1
                        ? "Employee"
                        : "Service Advisors"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <Link
                  to={`/employees/view/${employeeDetail?.id}`}
                  className="btn btn-parrot">
                  See Profile
                </Link>
              </div>
            </div>
          </div>
          <h5 className="dashboard-popup-title mb-20">Overview</h5>
          <div className="top-employee-count-wrap mb-20">
            <div className="row">
              <div className="col-md-4">
                <div className="top-employee-count is-total-won">
                  <div className="top-employee-count-row">
                    <div className="top-employee-count-icon">
                      <i className="icon icon-won"></i>
                    </div>
                    <div className="top-employee-count-text">
                      <h5 className="top-employee-count-count">
                        {employeeDetail?.totalWon}
                      </h5>
                      <span className="top-employee-count-label">
                        Completed
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="top-employee-count is-total-appraisals">
                  <div className="top-employee-count-row">
                    <div className="top-employee-count-icon">
                      <i className="icon icon-won"></i>
                    </div>
                    <div className="top-employee-count-text">
                      <h5 className="top-employee-count-count">
                        {employeeDetail?.appraisals}
                      </h5>
                      <span className="top-employee-count-label">
                        Appraisal
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="top-employee-count is-total-amount">
                  <div className="top-employee-count-row">
                    <div className="top-employee-count-icon">
                      <i className="icon icon-dollar"></i>
                    </div>
                    <div className="top-employee-count-text">
                      <h5 className="top-employee-count-count">
                        {employeeDetail?.totalAmount != null
                          ? formatCurrency(employeeDetail?.totalAmount)
                          : "No Offer"}
                      </h5>
                      <span className="top-employee-count-label">
                        Total Amount
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-16">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="dashboard-popup-title mb-0">
                  Best deals of {employeeDetail?.userName}
                </h4>
              </div>
              <div className="col-auto">
                <Link
                  className="btn btn-outline-grey"
                  to={`${routes.Appraisals}/?uid=${employeeDetail?.user_id}`}>
                  See all
                </Link>
              </div>
            </div>
          </div>
          <div className="dashboard-card">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Vehicle</th>
                  <th>VIN Number</th>
                  <th>Submitted Date</th>
                  <th>Offer Amount</th>
                </tr>
              </thead>
              <tbody>
                {employeeDetail?.topServiceWriter &&
                employeeDetail?.topServiceWriter.length != 0 ? (
                  employeeDetail?.topServiceWriter.map((data, key) => (
                    <tr key={key}>
                      <td width="3%">{data?.sequence_id}</td>
                      <td>
                        <div className="table-vehicle-image">
                          <img
                            src={
                              data?.vehicleImage != ""
                                ? data?.vehicleImage
                                : vehicleImg
                            }
                            alt=""
                          />
                        </div>
                        {data?.make} {data?.model} {data?.year}
                      </td>
                      <td>{data?.vin}</td>
                      <td>{moment(data?.created_at).format("MMMM D, YYYY")}</td>
                      <td>
                        {data?.deals_price != null
                          ? formatCurrency(data?.deals_price)
                          : "No Offer"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={5}>
                      {loading ? "Loading..." : "No Appraisal Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopEmployees;
