import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../Dashboard";
import Employees from "../Employees";
import EmployeesLogs from "../Employees/logs";
import { useState } from "react";
import { useRef } from "react";
import NotificationsPermissions from "../NotificationsPermissions";

const BusinessView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const logTabBtnRef = useRef(null);
  const [employeeId, setEmployeeId] = useState("");

  const showEmployeeLogs = (id) => {
    setEmployeeId(id);
    logTabBtnRef.current.click();
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-24">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <h2 className="page-title mb-0">Dealer #{id}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="appraisal-tabs">
        <ul className="nav nav-pills" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-dashboard-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-dashboard"
              type="button"
              role="tab"
              aria-controls="pills-dashboard"
              aria-selected="true">
              dashboard
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-employees-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-employees"
              type="button"
              role="tab"
              aria-controls="pills-employees"
              aria-selected="false">
              employees
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-logs-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-logs"
              type="button"
              role="tab"
              aria-controls="pills-logs"
              aria-selected="false"
              ref={logTabBtnRef}>
              Logs
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-notifications-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-notifications"
              type="button"
              role="tab"
              aria-controls="pills-notifications"
              aria-selected="false">
              Notifications
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-dashboard"
            role="tabpanel"
            aria-labelledby="pills-dashboard-tab">
            <Dashboard businessId={id} />
          </div>
          <div
            className="tab-pane fade"
            id="pills-employees"
            role="tabpanel"
            aria-labelledby="pills-employees-tab">
            <Employees businessId={id} showEmployeeLogs={showEmployeeLogs} />
          </div>
          <div
            className="tab-pane fade"
            id="pills-logs"
            role="tabpanel"
            aria-labelledby="pills-logs-tab">
            <EmployeesLogs employeeId={employeeId} businessId={id} />
          </div>
          <div
            className="tab-pane fade"
            id="pills-notifications"
            role="tabpanel"
            aria-labelledby="pills-notifications-tab">
            <NotificationsPermissions businessId={id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessView;
