import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import BusinessContext from "../../context/businessContext";
import DatePicker from "react-date-picker";
import moment from "moment";

const validationSchema = yup.object().shape({
  per_appraisal_spiff: yup
    .string()
    .max(5)
    .required("Per Appraisal Spiff is required."),
  per_accepted_appraisal_spiff: yup
    .string()
    .max(5)
    .required("Accepted Appraisal Spiff is required."),
});

const ContestCreate = () => {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (
      authCtx.userData?.user_type === 2 &&
      authCtx.userData?.user_type === 4
    ) {
      navigate(routes.Dashboard);
    }
  }, []);

  const [contestStartDate, setContestStartDate] = useState(new Date());
  const [contestEndDate, setContestEndDate] = useState(new Date());

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (authCtx.userData?.user_type == 2 || authCtx.userData?.user_type == 4) {
      navigate(routes.Dashboard);
    }
    getContestDetails();
  }, []);

  const getContestDetails = async () => {
    let payload = {};
    if (authCtx.userData?.user_type === 3) {
      payload = {
        ...payload,
        contest_id: id,
      };
    } else {
      payload = {
        ...payload,
        business_id: id,
      };
    }
    await api
      .post("api/contest/get", payload, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setIsEdit(true);
          setContestStartDate(new Date(response.data.data?.start_date));
          setContestEndDate(new Date(response.data.data?.end_date));
          setValue(
            "per_appraisal_spiff",
            response.data.data?.per_appraisal_spiff
          );
          setValue(
            "per_accepted_appraisal_spiff",
            response.data.data?.per_accepted_appraisal_spiff
          );
          setValue("business_id", response.data.data?.business_id);
          setValue("status", response.data.data?.status);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onSubmit = async (formValue) => {
    setLoading(true);
    formValue.start_date = moment(contestStartDate).utc().format("YYYY-MM-DD");
    formValue.end_date = moment(contestEndDate).utc().format("YYYY-MM-DD");
    await api
      .post("api/contest/add", formValue, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          navigate(routes.Contest);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(routes.Dashboard)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">
                  {isEdit ? `Update` : "Add New"} Contest
                </h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-user-inner">
        <div className="login-head">
          <h2 className="login-title">
            {isEdit ? "Update" : "Create"} Contest
          </h2>
          <p className="login-text">
            Please make sure to fill in all the required fields.
          </p>
        </div>
        <div className="login-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form-inner">
              <div className="row">
                {authCtx.userData?.user_type == 3 && (
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="form-label">
                        Business <span>*</span>
                      </label>
                      <div className="position-relative">
                        <select
                          className="form-control form-select"
                          {...register("business_id")}>
                          <option value="">Select Business</option>
                          {_.map(businessCtx.businessList, (option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Start Date <span>*</span>
                    </label>
                    <div className="position-relative">
                      <DatePicker
                        className="form-control"
                        onChange={setContestStartDate}
                        value={contestStartDate}
                        minDate={new Date()}
                        format="M-d-y"
                        yearPlaceholder="YYYY"
                        monthPlaceholder="MM"
                        dayPlaceholder="DD"
                        clearIcon={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      End Date <span>*</span>
                    </label>
                    <div className="position-relative">
                      <DatePicker
                        className="form-control"
                        onChange={setContestEndDate}
                        value={contestEndDate}
                        minDate={contestStartDate}
                        format="M-d-y"
                        yearPlaceholder="YYYY"
                        monthPlaceholder="MM"
                        dayPlaceholder="DD"
                        clearIcon={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Per Appraisal Spiff <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("per_appraisal_spiff")}
                        maxLength={5}
                      />
                      {errors.per_appraisal_spiff?.message != undefined && (
                        <span className="form-error">
                          {errors.per_appraisal_spiff?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      Per Accepted Appraisal Spiff <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("per_accepted_appraisal_spiff")}
                        maxLength={5}
                      />
                      {errors.per_accepted_appraisal_spiff?.message !=
                        undefined && (
                        <span className="form-error">
                          {errors.per_accepted_appraisal_spiff?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label mb-0 align-middle">
                      Status
                    </label>
                    <div className="switch-button">
                      <input
                        type="checkbox"
                        hidden="hidden"
                        {...register("status")}
                        id={`status`}
                        defaultChecked={true}
                      />
                      <label className="switch" htmlFor={`status`}></label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-pink"
                    disabled={loading}>
                    <i className="icon icon-appraisals"></i>
                    {isEdit ? "Update" : "Add"} Contest
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContestCreate;
