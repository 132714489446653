import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api";
import routes from "../../constants/routesConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AuthContext from "../../context/authContext";
import { useForm } from "react-hook-form";
import Swal from "../Swal";
import _ from "lodash";
import { formatPhoneNumber } from "../../utils/formats";
import { useEffect } from "react";

const validationSchema = yup.object().shape({
  name: yup.string().max(42).required("Name is required."),
  business_name: yup.string().max(42).required("Business Name is required."),
  email: yup.string().email("Email is invalid.").max(42).required("Email is required."),
  business_phone: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Phone number is to short.")
    .max(12, "Phone number is to long."),
  business_address: yup.string().max(150),
});

const BusinessCreate = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const business_phone = watch("business_phone");
  useEffect(() => {
    setValue("business_phone", formatPhoneNumber(business_phone).formatted);
  }, [business_phone]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("business_name", formValue.business_name);
    formData.append("name", formValue.name);
    formData.append("email", formValue.email);
    formData.append(
      "business_phone",
      formatPhoneNumber(formValue.business_phone).base
    );
    if (formValue.image != undefined) {
      formData.append("image", formValue.image[0]);
    }
    formData.append("business_address", formValue.business_address);
    await api
      .post("api/dealers/add-dealer", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          reset();
          navigate(routes.Business);
        } else {
          Swal.fire("Try Again!", response.data.message, "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-34">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title align-items-start">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <div className="page-head">
                <h2 className="page-title">Add New Dealer</h2>
                <p className="page-dec">Fill the required informations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-user-inner">
        <div className="login-head">
          <h2 className="login-title">Invite your dealer to join you here</h2>
          <p className="login-text">Enter basic details to invite dealer</p>
        </div>
        <div className="login-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="login-form-inner">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Business Name <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        {...register("business_name")}
                        maxLength={42}
                      />
                      {errors.business_name?.message != undefined && (
                        <span className="form-error">
                          {errors.business_name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">
                      Name <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        {...register("name")}
                        maxLength={42}
                      />
                      {errors.name?.message != undefined && (
                        <span className="form-error">
                          {errors.name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="form-label">
                      Email <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input className="form-control" {...register("email")} maxLength={42} />
                      {errors.email?.message != undefined && (
                        <span className="form-error">
                          {errors.email?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      Phone <span>*</span>
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("business_phone")}
                        maxLength={12}
                      />
                      {errors.business_phone?.message != undefined && (
                        <span className="form-error">
                          {errors.business_phone?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">Business Address</label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        {...register("business_address")}
                        maxLength={150}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label className="form-label">Business Logo</label>
                    <input
                      type="file"
                      className="form-control"
                      {...register("image")}
                      accept="image/png, image/jpg, image/jpeg"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="notice">
                    <p>
                      Your dealer will receive an email with a link to join this
                      workspace.
                    </p>
                  </div>
                </div>

                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-pink"
                    disabled={loading}>
                    <i className="icon icon-add-user"></i>
                    Add Dealer
                  </button>
                </div>
                <div className="col-lg-12">
                  <p className="login-form-login-link">
                    <Link to={routes.Business} className="cancel">
                      Cancel
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BusinessCreate;
