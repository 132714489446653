import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import Swal from "../Swal";
import { formatCurrency } from "../../utils/formats";
import ReactPaginate from "react-paginate";
import moment from "moment";
import _ from "lodash";
import downloadCsv from "download-csv";
import BusinessContext from "../../context/businessContext";

const CommissionReport = () => {
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const authCtx = useContext(AuthContext);
    const businessCtx = useContext(BusinessContext);

    const [sortField, setSortField] = useState("");
    const [sortType, setSortType] = useState("desc");
    const [pagination, setPagination] = useState({
        total_records: 0,
        total_pages: 0,
        current: 0,
    });

    useEffect(() => {
        api
            .post(
                `api/service-writer`,
                { business_id: businessCtx.selectedBusinessId },
                {
                    headers: {
                        Authorization: `Bearer ${authCtx.userData?.token}`,
                    },
                }
            )
            .then((response) => {
                if (response.data.status) {
                    let employeesArray = [];
                    if (response.data.data.length > 0) {
                        response.data.data.map((element) => {
                            employeesArray.push({
                                value: element.id,
                                label: element.name,
                            });
                        });
                        setEmployees(employeesArray);
                    } else {
                        setEmployees([]);
                    }
                } else {
                    setEmployees([]);
                }
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    authCtx.logout();
                } else {
                    Swal.fire(
                        "Opps!",
                        "Sorry we are not able to complete your request at this time. Please try again later.",
                        "error"
                    );
                }
            });
    }, [businessCtx.selectedBusinessId]);

    useEffect(() => {
        getReportData({}, 1);
    }, [selectedEmployee, businessCtx.selectedBusinessId]);

    const getReportData = async (formData = {}, page) => {
        setLoading(true);
        if (
            authCtx.userData?.user_type == 3 &&
            businessCtx.selectedBusinessId != ""
        ) {
            formData.business_id = businessCtx.selectedBusinessId;
        }
        if (selectedEmployee != "") {
            formData.user_id = selectedEmployee;
        }
        await api
            .post(`api/report/commission?page=${page}`, formData, {
                headers: {
                    Authorization: `Bearer ${authCtx.userData?.token}`,
                },
            })
            .then((res) => {
                setLoading(false);
                if (res.data.status) {
                    setResponseData(res.data.data.lead);
                    setPagination(res.data.data.paginate);
                } else {
                    setResponseData([]);
                    setPagination({
                        total_records: 0,
                        total_pages: 0,
                        current: 0,
                    });
                }
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    authCtx.logout();
                } else {
                    Swal.fire(
                        "Opps!",
                        "Sorry we are not able to complete your request at this time. Please try again later.",
                        "error"
                    );
                }
            });
    };


    function sort(field, currentField, sort, page) {
        let filterData = {};
        if (field == currentField) {
            if (sort == "desc") {
                setSortType("asc");
                filterData = {
                    ...filterData,
                    field_name: field,
                    order_by: "asc",
                };
                getReportData(filterData, page);
            } else {
                setSortType("desc");
                filterData = {
                    ...filterData,
                    field_name: field,
                    order_by: "desc",
                };
                getReportData(filterData, page);
            }
        } else {
            setSortField(field);
            setSortType("desc");
            filterData = {
                ...filterData,
                field_name: field,
                order_by: "desc",
            };
            getReportData(filterData, page);
        }
    }

    const onPaginationFetch = (e) => {
        let filterData = {};
        if (sortField != "" && sortType != "") {
            filterData = {
                ...filterData,
                field_name: sortField,
                order_by: sortType,
            };
        }
        getReportData(filterData, e.selected + 1);
    };

    const clearHandler = () => {
        setSelectedEmployee("");
    };

    const onEmployeeChange = async (value) => {
        setSelectedEmployee(value);
    };

    const currentDateTime = new Date();
    const exportHandler = async () => {
        let filterData = {
            action: "export",
        };
        if (sortField != "" && sortType != "") {
            filterData = {
                ...filterData,
                field_name: sortField,
                order_by: sortType,
            };
        }
        if (selectedEmployee != "") {
            filterData = {
                ...filterData,
                user_id: selectedEmployee,
            };
        }
        if (
            authCtx.userData?.user_type == 3 &&
            businessCtx.selectedBusinessId != ""
        ) {
            filterData = {
                ...filterData,
                business_id: businessCtx.selectedBusinessId,
            };
        }
        await api
            .post("api/report/commission", filterData, {
                headers: {
                    Authorization: `Bearer ${authCtx.userData?.token}`,
                },
            })
            .then((response) => {
                setLoading(false);
                if (response.data.status) {
                    let exportData = response.data.data.map((item) => {
                        return {
                            name: item.employee_name,
                            vehicle: `${item.make} ${item.model} ${item.year}`,
                            customer: `${item.first_name} ${item.last_name}`,
                            submit_appraisal_bonus: item.submit_appraisal_bonus,
                            submit_appraisal_contest: item.submit_appraisal_contest,
                            submit_appraisal_wishlist: item.submit_appraisal_wishlist,
                            appraisal_won_bonus: item.appraisal_won_bonus,
                            appraisal_won_contest: item.appraisal_won_contest,
                            appraisal_won_wishlist: item.appraisal_won_wishlist,
                        };
                    });

                    let exportColumns = {
                        employee_name: "Service Advisor",
                        vehicle: "Vehicle",
                        customer: "Customer Name",
                        submit_appraisal_bonus: "Submit Bonus",
                        submit_appraisal_contest: "Submit Contest",
                        submit_appraisal_wishlist: "Submit Wishlist",
                        appraisal_won_bonus: "Accepted Bonus",
                        appraisal_won_contest: "Accepted Contest",
                        appraisal_won_wishlist: "Accepted Wishlist",
                    };

                    downloadCsv(
                        exportData,
                        exportColumns,
                        `commission-report-${moment(currentDateTime).format("MM-DD-YYYY")}`
                    );
                } else {
                    Swal.fire("Try Again!", response.data.message, "info");
                }
            })
            .catch((error) => {
                setLoading(false);
                if (error.response?.status == 401) {
                    authCtx.logout();
                } else {
                    Swal.fire(
                        "Opps!",
                        "Sorry we are not able to complete your request at this time. Please try again later.",
                        "error"
                    );
                }
            });
    };

    return (
        <>
            <div className="dashboard-card-filter dashboard-count-filter">
                <div className="row align-items-center">
                    <div className="col">
                        <div className="dashboard-navbar-month dashboardml">
                            <div className="form-group">
                                <select
                                    className="form-select"
                                    value={selectedEmployee}
                                    onChange={(e) => onEmployeeChange(e.target.value)}>
                                    <option value="">Select Employee</option>
                                    {_.map(employees, (option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="dashboard-navbar-date-filter dashboardml">
                            <button
                                type="button"
                                className="date-filter"
                                onClick={() => clearHandler()}>
                                Clear <i className="icon icon-reset"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-auto dashboard-export-sticky reports-page">
                        <div className="dashboard-export">
                            <button
                                type="button"
                                className="btn btn-pink"
                                onClick={() => exportHandler()}>
                                <i className="icon icon-export"></i> Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-latest-appraisals">
                <div className="dashboard-card p-0">
                    <div className="dashboard-latest-apraisals business-table table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>
                                        Service Advisor {" "}
                                        <span
                                            className={`table-sort ${sortField == "employee_name" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort("employee_name", sortField, sortType, pagination.current)
                                            }></span>
                                    </th>
                                    <th>
                                        Vehicle{" "}
                                        <span
                                            className={`table-sort ${sortField == "year" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort("year", sortField, sortType, pagination.current)
                                            }></span>
                                    </th>
                                    <th>
                                        Customer Name{" "}
                                        <span
                                            className={`table-sort ${sortField == "first_name" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort("first_name", sortField, sortType, pagination.current)
                                            }></span>
                                    </th>
                                    <th>
                                        Submit Bonus{" "}
                                        <span
                                            className={`table-sort ${sortField == "submit_appraisal_bonus" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort("submit_appraisal_bonus", sortField, sortType, pagination.current)
                                            }></span>
                                    </th>
                                    <th>
                                        Submit Contest{" "}
                                        <span
                                            className={`table-sort ${sortField == "submit_appraisal_contest" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort("submit_appraisal_contest", sortField, sortType, pagination.current)
                                            }></span>
                                    </th>
                                    <th>Submit Wishlist{" "}<span
                                            className={`table-sort ${sortField == "submit_appraisal_wishlist" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort("submit_appraisal_wishlist", sortField, sortType, pagination.current)
                                            }></span></th>
                                    <th>
                                        Accepted Bonus{" "}
                                        <span
                                            className={`table-sort ${sortField == "appraisal_won_bonus" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort(
                                                    "appraisal_won_bonus",
                                                    sortField,
                                                    sortType,
                                                    pagination.current
                                                )
                                            }></span>
                                    </th>
                                    <th>
                                        Accepted Contest{" "}
                                        <span
                                            className={`table-sort ${sortField == "appraisal_won_contest" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort("appraisal_won_contest", sortField, sortType, pagination.current)
                                            }></span>
                                    </th>
                                    <th>Accepted Wishlist{" "}<span
                                            className={`table-sort ${sortField == "appraisal_won_wishlist" ? sortType : ""
                                                }`}
                                            onClick={() =>
                                                sort("appraisal_won_wishlist", sortField, sortType, pagination.current)
                                            }></span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {responseData?.length !== 0 ? (
                                    responseData?.map((data) => (
                                        <tr key={data?.id}>
                                            <td width="3%">
                                                {data?.auto_id}
                                            </td>
                                            <td>
                                                {data?.employee_name}
                                            </td>
                                            <td>
                                                {data?.make} {data?.model}{" "}
                                                {data?.year}
                                            </td>
                                            <td>
                                                {data?.first_name}{" "}
                                                {data?.last_name}
                                            </td>
                                            <td>
                                                {formatCurrency(data?.submit_appraisal_bonus)}
                                            </td>
                                            <td>
                                                {formatCurrency(data?.submit_appraisal_contest)}
                                            </td>
                                            <td>
                                                {formatCurrency(data?.submit_appraisal_wishlist)}
                                            </td>
                                            <td>
                                                {formatCurrency(data?.appraisal_won_bonus)}
                                            </td>
                                            <td>
                                                {formatCurrency(data?.appraisal_won_contest)}
                                            </td>
                                            <td>
                                                {formatCurrency(data?.appraisal_won_wishlist)}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr className="text-start text-sm-center">
                                        <td colSpan={12}>
                                            {loading ? "Loading..." : "No Data Found."}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
                <nav className="navigation pagination">
                    <ReactPaginate
                        breakLabel="..."
                        onPageChange={onPaginationFetch}
                        pageRangeDisplayed={2}
                        forcePage={parseInt(pagination?.current - 1)}
                        pageCount={pagination?.total_pages}
                        renderOnZeroPageCount={null}
                        containerClassName="nav-links"
                        activeLinkClassName="current"
                        pageLinkClassName="page-numbers"
                        previousLinkClassName="prev page-numbers"
                        previousLabel={<i className="icon icon-arrow-left"></i>}
                        nextLinkClassName="next page-numbers"
                        nextLabel={<i className="icon icon-arrow-right"></i>}
                    />
                </nav>
            )}
        </>
    );
};

export default CommissionReport;
