import React, { Component } from "react";
import PropTypes from "prop-types";
import { configure, BarcodePicker as ScanditSDKBarcodePicker } from "scandit-sdk";

const configurationPromise = configure(
    "AZvDiQ6VLZa2KcZkDzPEbPQbxArdCR3/gmtR5ScHYTGFAU5kjEftSm9P6hI7fJ9UhHUkAmp3Pq3BNzPA93Erq85nYwnyNs7k5GvNz+YK3Aw5RxLDYHwlpl1R1hu/Vv1JtmhAcAojhSlsdJZkDk/nvAFkIoN/Xv1iLiXAUW956dj/fz0yn0HHuZJvskrLV6KyX37xvR4r3kPRKDESpBL1J/8EPzlm12pJ4ZQrtg3UX52W01uRQnIbvcWD9Z4I7oflFeh7ZQAPB5/b8T8Z7V7if5XnqNkeMeQYL826NiiZYUW5Sbuebw9mV4cPNJdS35mye96kYrYdK/lO4NZLCHsSwaiN2JRGaUpTouOR96EN4RCdaWk+pMfEcN3Kv80G8j0K7bR1Srgr2Bq6eb05JDEtYJk97TdItHKg0JGSMIvu8aao/2TrrIaqz7C3mbje2YebiyA2VGtRY8Qmlmq5NKEc5gLusdswJXLuhS05ijrrr18nKkSx0L4lO4RAiA4eo1c+ovHRarh5P0xz7CllnexnVHZBlDQkyLI1Djsif8PrXtOW8i2G9kkVFbMgoU3jeWM6B/RxBi4ESoohU7cHda0RU/JawzpdRdU8iLdx+7n3TwaLkTdaWfnXnxK/tJjsFTlqOcEQC3lZ6cDbVJFDYi1AwfW8siODlRAWeaY/tR0J9SavmLERCcn7+Uj3N4ursN2PoGhgrpZzEadt2IFWn/i2+z2hVzitnQG41ch8PoPp+HwLHqIE+mOvEOmj2Tpb6iJ0q7bhf57HfKpFEtQWYh9fg01Hgv/GIdwE5BuxDbwnl3ay6qjMNbNU7MHu+zu4MbYw8lhYMYEiIRhNFqkeHyUR5IJySoRyL5/PsvoLDKXn90veoz/LL8Xy1gfNstArDRXF1WlCKg=="
    , { engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/" }).catch((error) => {
        alert(error);
    });

class BarcodePicker extends Component {
    static propTypes = {
        visible: PropTypes.bool,
        playSoundOnScan: PropTypes.bool,
        vibrateOnScan: PropTypes.bool,
        scanningPaused: PropTypes.bool,
        guiStyle: PropTypes.string,
        videoFit: PropTypes.string,
        scanSettings: PropTypes.object,
        enableCameraSwitcher: PropTypes.bool,
        enableTorchToggle: PropTypes.bool,
        enableTapToFocus: PropTypes.bool,
        enablePinchToZoom: PropTypes.bool,
        accessCamera: PropTypes.bool,
        camera: PropTypes.object,
        cameraSettings: PropTypes.object,
        targetScanningFPS: PropTypes.number,
        onScan: PropTypes.func,
        onError: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        configurationPromise.then(() => {
            ScanditSDKBarcodePicker.create(this.ref.current, this.props).then((barcodePicker) => {
                this.barcodePicker = barcodePicker;
                if (this.props.onScan != null) {
                    barcodePicker.on("scan", this.props.onScan);
                }
                if (this.props.onError != null) {
                    barcodePicker.on("scanError", this.props.onError);
                }
            });
        });
    }

    componentWillUnmount() {
        if (this.barcodePicker != null) {
            this.barcodePicker.destroy();
        }
    }

    componentDidUpdate(prevProps) {
        // These are just some examples of how to react to some possible property changes

        if (JSON.stringify(prevProps.scanSettings) !== JSON.stringify(this.props.scanSettings)) {
            this.barcodePicker.applyScanSettings(this.props.scanSettings);
        }

        if (prevProps.visible !== this.props.visible) {
            this.barcodePicker.setVisible(this.props.visible);
        }
    }

    render() {
        return <div ref={this.ref} />;
    }
}

export default BarcodePicker;
