import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../context/authContext";
import carfaxLogo from "../../assets/img/carfax.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useForm } from "react-hook-form";
import api from "../../api";
import routes from "../../constants/routesConstants";
import _ from "lodash";
import Swal from "../Swal";
import { formatPhoneNumber, formatPrice } from "../../utils/formats";
import { isMobile } from "react-device-detect";
import VinScanner from "./vinScanner";

const years = _.map(
  new Array(new Date().getFullYear() + 1 - 1990),
  (_value, idx) => ({
    label: new Date().getFullYear() + 1 - idx,
    value: new Date().getFullYear() + 1 - idx,
  })
);

const validationSchema = yup.object().shape({
  first_name: yup.string().max(42).required("First name is required."),
  last_name: yup.string().max(42).required("Last name is required."),
  email: yup.string().max(42).email("Email is invalid."),
  mobile_no: yup.string().max(12, "Phone number is to long."),
  vin: yup
    .string()
    .required("VIN number is required")
    .min(17, "Please Enter Minimum 17 Characters.")
    .max(17, "Please Enter Maximum 17 Characters."),
  year: yup.string().required("Year is required."),
  make: yup.string().required("Make is required."),
  model: yup.string().required("Model is required."),
  trim: yup.string().required("Trim is required."),
  mileage: yup.string().max(5).required("Mileage is required."),
});

const AppraisalCreate = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const countRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const trimRef = useRef();
  const [loading, setLoading] = useState(false);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [autoFill, setAutoFill] = useState(false);
  const [autoFillMake, setAutoFillMake] = useState(false);

  useEffect(() => {
    if (authCtx.userData?.user_type !== 2) {
      navigate(routes.Dashboard);
    }
  }, [authCtx.userData?.user_type]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const price = watch("price");
  useEffect(() => {
    setValue("price", formatPrice(price).formatted);
  }, [price]);

  const onSubmit = async (formValue) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("first_name", formValue.first_name);
    formData.append("last_name", formValue.last_name);
    formData.append("email", formValue.email);
    formData.append("mobile_no", formatPhoneNumber(formValue.mobile_no).base);
    formData.append("vin", formValue.vin);
    formData.append("year", formValue.year);
    formData.append(
      "make",
      countRef.current
        ? _.toString(formValue.make)
        : _.toString(makeRef.current)
    );
    formData.append(
      "model",
      countRef.current
        ? _.toString(formValue.model)
        : _.toString(modelRef.current)
    );
    formData.append(
      "trim",
      countRef.current
        ? _.toString(formValue.trim)
        : _.toString(trimRef.current)
    );
    formData.append("mileage", formValue.mileage);
    formData.append("price", formatPrice(formValue.price).base);
    formData.append("interior_color", formValue.interior_color);
    formData.append("exterior_color", formValue.exterior_color);
    formData.append(
      "estimated_repair_order_cost",
      formValue.estimated_repair_order_cost
    );
    let files = [];
    formValue.image.forEach((file) => {
      files.push(file.file);
    });
    for (var i = 0; i < files.length; i++) {
      formData.append("image[]", files[i]);
    }
    await api
      .post("api/leads/add-lead", formData, {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Swal.fire(response.data.message, "", "success");
          navigate(routes.Appraisals);
        } else {
          Swal.fire(response.data.message, "", "info");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const mobile_no = watch("mobile_no");
  useEffect(() => {
    setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
  }, [mobile_no]);

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "image",
  });

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: (image) =>
      image.forEach((file) => {
        append({
          name: file.name,
          preview: URL.createObjectURL(file),
          file: file,
        });
      }),
  });

  const formData = watch();

  useEffect(() => {
    countRef.current = autoFill;
  }, [autoFill]);

  useEffect(() => {
    if (formData.vin) {
      if (formData.vin.length == 17) {
        api
          .get(`api/leads/chrome-data?vin=${formData.vin}`, {
            headers: {
              Authorization: `Bearer ${authCtx.userData?.token}`,
            },
          })
          .then((response) => {
            if (response.data.status) {
              if (response.data.data.year != "0") {
                if (response.data.data.year) {
                  setAutoFill(true);
                  setValue("year", response.data.data.year);
                }
                if (response.data.data.model != "") {
                  setValue("model", response.data.data.model);
                }
                if (response.data.data.trim != "") {
                  setValue("trim", response.data.data.trim);
                }
                if (response.data.data.make != "") {
                  setAutoFillMake(true);
                  setValue("make", response.data.data.make);
                } else {
                  setAutoFill(false);
                }
              } else {
                setAutoFill(false);
                setAutoFillMake(false);
              }
            } else {
              setAutoFill(false);
              setAutoFillMake(false);
            }
          })
          .catch((error) => {});
      } else {
        setAutoFill(false);
        setAutoFillMake(false);
      }
    }
  }, [formData.vin]);

  const yearChange = async (value, key) => {
    setValue("year", value);
    await api
      .get(`api/leads/chrome-data?getMake=true&modelYear=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setModels([]);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let makeArray = [];
            Object.entries(response.data.data).forEach((element) => {
              makeArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setMakes(makeArray);
          } else {
            setMakes([]);
          }
        } else {
          setMakes([]);
        }
      })
      .catch((error) => {});
  };

  const makeChange = async (value, key) => {
    makeRef.current = key;
    await api
      .get(
        `api/leads/chrome-data?getModel=true&modelYear=${formData.year}&modelMake=${value}`,
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let modelArray = [];
            Object.entries(response.data.data).forEach((element) => {
              modelArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setModels(modelArray);
          } else {
            setModels([]);
          }
        } else {
          setModels([]);
        }
      })
      .catch((error) => {
        setModels([]);
      });
  };

  const modelChange = async (value, key) => {
    modelRef.current = key;
    await api
      .get(`api/leads/chrome-data?getTrim=true&modelId=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let trimArray = [];
            Object.entries(response.data.data).forEach((element) => {
              trimArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setTrims(trimArray);
          } else {
            setTrims([]);
          }
        } else {
          setTrims([]);
        }
      })
      .catch((error) => {
        setTrims([]);
      });
  };

  const trimChange = (value, key) => {
    trimRef.current = key;
  };

  const onScanComplete = (vin) => {
    setValue("vin", vin);
  };

  return (
    <>
      <div className="appraisal-head-wrap mb-16">
        <div className="row">
          <div className="col">
            <div className="appraisal-head-title">
              <button
                type="button"
                className="btn-icon"
                onClick={() => navigate(-1)}>
                <i className="icon icon-back-aero"></i>
              </button>
              <h2 className="page-title mb-0">Add New Appraisal</h2>
            </div>
          </div>
          <div className="col-auto">
            <div className="appraisal-head-buttons">
              <Link
                to={routes.Appraisals}
                className="btn btn-outline-secondary">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="login-inner add-new-appraisal-inner">
        <div className="add-new-appraisal-card">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="appraisal-form-detail customer-details">
              <div className="row">
                <div className="col-xl-6 col-lg-12 appraisalcol mb-0">
                  <div className="appraisal-form-head">
                    <div className="row">
                      <div className="col">
                        <h5 className="card-title">Customer</h5>
                      </div>
                    </div>
                  </div>
                  <div className="appraisal-form-inner mb-24">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">First Name</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("first_name")}
                            maxLength={42}
                          />
                          {errors.first_name?.message != undefined && (
                            <span className="form-error">
                              {errors.first_name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="col-form-label">Last Name</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("last_name")}
                            maxLength={42}
                          />
                          {errors.last_name?.message != undefined && (
                            <span className="form-error">
                              {errors.last_name?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">Phone</label>
                          <input
                            className="form-control"
                            type="text"
                            {...register("mobile_no")}
                            maxLength={12}
                          />
                          {errors.mobile_no?.message != undefined && (
                            <span className="form-error">
                              {errors.mobile_no?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="col-form-label">Email</label>
                          <input
                            className="form-control"
                            type="email"
                            {...register("email")}
                            maxLength={42}
                          />
                          {errors.email?.message != undefined && (
                            <span className="form-error">
                              {errors.email?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="appraisal-form-detail vehicle-details">
                    <div className="appraisal-form-head">
                      <div className="row align-items-center">
                        <div className="col">
                          <h5 className="card-title">Vehicle</h5>
                        </div>
                        <div className="col-auto">
                          <a
                            href={`https://www.carfax.com/VehicleHistory/p/Report.cfx?partner=aan_0&vin=${formData.vin}`}
                            target="_blank">
                            <img src={carfaxLogo} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="appraisal-form-inner mb-24">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Vin</label>
                            <div className="position-relative flex-fill">
                              <input
                                className="form-control"
                                type="text"
                                maxLength={17}
                                {...register("vin")}
                              />
                              <VinScanner onScanComplete={onScanComplete} />
                            </div>
                            {errors.vin?.message != undefined && (
                              <span className="form-error">
                                {errors.vin?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Year</label>
                            <select
                              className="form-select"
                              {...register("year")}
                              disabled={autoFill}
                              onChange={(e) =>
                                yearChange(
                                  e.target.value,
                                  e.target.options[e.target.selectedIndex].text
                                )
                              }>
                              <option value="" selected={!autoFill}>
                                Select Year
                              </option>
                              {_.map(years, (option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {errors.year?.message != undefined && (
                              <span className="form-error">
                                {errors.year?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Make</label>
                            {!autoFillMake && (
                              <select
                                className="form-select"
                                {...register("make")}
                                onChange={(e) =>
                                  makeChange(
                                    e.target.value,
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  )
                                }>
                                <option value="" selected={!autoFill}>
                                  Select Make
                                </option>
                                {_.map(makes, (option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                            {autoFillMake && (
                              <input
                                className="form-control"
                                {...register("make")}
                                disabled={autoFillMake}
                              />
                            )}
                            {errors.make?.message != undefined && (
                              <span className="form-error">
                                {errors.make?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Model</label>
                            {!autoFillMake && (
                              <select
                                className="form-select"
                                {...register("model")}
                                onChange={(e) =>
                                  modelChange(
                                    e.target.value,
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  )
                                }>
                                <option value="" selected={!autoFill}>
                                  Select Model
                                </option>
                                {_.map(models, (option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                            {autoFillMake && (
                              <input
                                className="form-control"
                                {...register("model")}
                              />
                            )}
                            {errors.model?.message != undefined && (
                              <span className="form-error">
                                {errors.model?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Trim</label>
                            {!autoFillMake && (
                              <select
                                className="form-select"
                                {...register("trim")}
                                onChange={(e) =>
                                  trimChange(
                                    e.target.value,
                                    e.target.options[e.target.selectedIndex]
                                      .text
                                  )
                                }>
                                <option value="" selected={!autoFill}>
                                  Select Trim
                                </option>
                                {_.map(trims, (option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            )}
                            {autoFillMake && (
                              <input
                                className="form-control"
                                {...register("trim")}
                              />
                            )}
                            {errors.trim?.message && (
                              <span className="form-error">
                                {errors.trim?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">Mileage</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("mileage")}
                              maxLength={42}
                            />
                            {errors.mileage?.message != undefined && (
                              <span className="form-error">
                                {errors.mileage?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Exterior Color
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("exterior_color")}
                              maxLength={42}
                            />
                            {errors.exterior_color?.message != undefined && (
                              <span className="form-error">
                                {errors.exterior_color?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Interior Color
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("interior_color")}
                              maxLength={42}
                            />
                            {errors.interior_color?.message != undefined && (
                              <span className="form-error">
                                {errors.interior_color?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 appraisalcol">
                  <div className="appraisal-form-detail vehicle-details without-title">
                    <div className="appraisal-form-inner">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mt-0">
                            <label className="col-form-label">
                              Asking Price
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("price")}
                              maxLength={5}
                            />
                            {errors.price?.message != undefined && (
                              <span className="form-error">
                                {errors.price?.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-24">
                            <label className="col-form-label">
                              Estimated Repair {isMobile && <br />} Order Cost
                            </label>
                            <select
                              className="form-select"
                              {...register("estimated_repair_order_cost")}>
                              <option value="">Select Cost</option>
                              <option value="$0 - $499">$0 - $499</option>
                              <option value="$500 - $999">$500 - $999</option>
                              <option value="$1,000 - $1,999">
                                $1,000 - $1,999
                              </option>
                              <option value="$2,000 - $3,999">
                                $2,000 - $3,999
                              </option>
                              <option value="Over $4,000">Over $4,000</option>
                            </select>
                            {errors.estimated_repair_order_cost?.message !=
                              undefined && (
                              <span className="form-error">
                                {errors.estimated_repair_order_cost?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="appraisal-form-detail vehicle-photos">
                    <div className="appraisal-form-head">
                      <div className="row align-items-center">
                        <div className="col">
                          <h5 className="card-title">Vehicle Photos</h5>
                        </div>
                        <div className="col-auto">
                          <div className="upload-button" {...getRootProps()}>
                            <i className="icon icon-upload"></i>
                            <span>Upload Photos</span>
                            <input {...getInputProps()} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="appraisal-form-inner">
                      <div className="row">
                        {fields.map((f, index) => (
                          <div
                            className="col-lg-3 col-sm-3 vehicleuplaodcol"
                            key={index}>
                            <div className="vehicle-photo-wrap">
                              <img
                                src={f.preview}
                                alt=""
                                className="img-fluid"
                              />
                              <i
                                className="icon icon-close"
                                onClick={() => remove(index)}></i>
                            </div>
                          </div>
                        ))}
                        <div className="col-lg-3 col-sm-3 vehicleuplaodcol">
                          <div
                            className="vehicle-photos-upload"
                            {...getRootProps()}>
                            <i className="icon icon-add-picture"></i>
                            <input {...getInputProps()} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="new-appraisal-card-submit">
                    <button
                      type="submit"
                      className="btn btn-pink"
                      disabled={loading}>
                      {loading && (
                        <div
                          className="spinner-border spinner-border-sm "
                          role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}{" "}
                      Submit the Appraisal
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AppraisalCreate;
