import { useContext } from "react";
import {
  APPRAISALS_ADD,
  BUSINESS_LIST,
  FAQ_LIST,
  INQUIRIES_LIST,
  SCHEDULE_DEMO_LIST,
  ROLE_LIST,
} from "../constants/permissionConstants";
import AuthContext from "../context/authContext";

function usePermission(permission_code = "") {
  const authCtx = useContext(AuthContext);
  if (authCtx.userData?.user_type === 3 && permission_code !== APPRAISALS_ADD) {
    return true;
  } else if (
    authCtx.userData?.user_type === 0 &&
    permission_code !== BUSINESS_LIST &&
    permission_code !== SCHEDULE_DEMO_LIST &&
    permission_code !== INQUIRIES_LIST &&
    permission_code !== FAQ_LIST &&
    permission_code !== ROLE_LIST &&
    permission_code !== APPRAISALS_ADD
  ) {
    return true;
  } else if (
    authCtx.userData?.user_type === 1 &&
    permission_code === APPRAISALS_ADD
  ) {
    return false;
  } else if (
    permission_code !== "" &&
    authCtx.userData?.permissions?.length !== 0 &&
    authCtx.userData?.permissions?.includes?.(permission_code)
  ) {
    return true;
  } else {
    return false;
  }
}

export default usePermission;
