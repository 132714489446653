import React, { useContext, useState } from "react";
import ThemeContext from "../../context/themeContext";
import BarcodePicker from "./barcodePicker";
import { ScanSettings, Barcode } from "scandit-sdk";

const VinScanner = ({ onScanComplete }) => {
  const themeCtx = useContext(ThemeContext);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [isScannerLoaded, setIsScannerLoaded] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [vin, setVin] = useState("");

  const onScan = (data) => {
    data.barcodes.reduce(function (string, barcode) {
      setVin(barcode.data);
    }, "");
    themeCtx.showDropShadowHandler();
    setConfirmOpen(true);
  };

  const onScannerClose = () => {
    setIsScannerOpen(false);
    setConfirmOpen(false);
    setIsScannerLoaded(false);
    themeCtx.hideDropShadowHandler();
  };

  const onConfirmClick = () => {
    onScannerClose();
    onScanComplete(vin);
  };

  const scanSettingsR = new ScanSettings({
    enabledSymbologies: ["code39"],
    codeDuplicateFilter: 1000,
  });
  scanSettingsR.getSymbologySettings(Barcode.Symbology.CODE39).isColorInvertedEnabled(true);

  return (
    <>
      <button
        type="button"
        className="scanner-open-btn position-absolute top-50 p-0 border-0 bg-transparent d-flex align-items-center justify-content-center"
        onClick={() => setIsScannerOpen(true)}>
        <i className="icon icon-barcode"></i>
      </button>
      {isScannerOpen && (
        <div className={`scanner-wrap ${isScannerLoaded ? "visible" : "visible"}`}>
          <button
            type="button"
            className="scanner-close-btn border-0 position-absolute d-flex align-items-center justify-content-center rounded-circle"
            onClick={() => onScannerClose()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </button>
          <BarcodePicker
            playSoundOnScan={true}
            vibrateOnScan={true}
            scanSettings={scanSettingsR}
            onScan={onScan}
            onError={error => {
              console.error(error.message);
            }}
          />
        </div>
      )}
      <div className={`confirm-popup ${isConfirmOpen ? "active" : ""}`}>
        <div className="confirm-popup-inr">
          <button
            type="button"
            className="popup-close-btn"
            onClick={() => onScannerClose()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
            </svg>
          </button>
          <div className="confirm-popup-con">
            <h5 className="confirm-popup-title text-white">
              Please confirm VIN showing below!
            </h5>
            {vin !== "" && <p className="text-uppercase text-white">{vin}</p>}
          </div>
          <div className="confirm-popup-btns">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => onScannerClose()}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-pink"
              onClick={() => onConfirmClick()}>
              Confirm
            </button>
          </div>
        </div>
      </div >
    </>
  );
};

export default VinScanner;
