import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import AuthContext from "../../context/authContext";
import moment from "moment";
import Swal from "../Swal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import BusinessContext from "../../context/businessContext";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
    },
  },
};

const labels = ["Total Accepted Offer", "Total No Deal"];

const ClosingReport = () => {
  const [responseData, setResponseData] = useState({});
  const [filterType, setFilterType] = useState(4);
  const [selectedDate, setSelectedDate] = useState(null);
  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);

  useEffect(() => {
    if (filterType != 5) {
      getReportData({ date_type: filterType });
    } else if (filterType == 5) {
      getReportData({ date_type: 4 });
    }
  }, [filterType, businessCtx.selectedBusinessId]);

  const getReportData = async (formData = {}) => {
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    await api
      .post("api/report/appraisal-closing", formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((res) => {
        setResponseData(res.data.data);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  const onRangeChange = (value) => {
    setSelectedDate(value);
    if (value != null) {
      getReportData({
        date_type: filterType,
        start_date: moment(value[0]).format("YYYY-MM-DD"),
        end_date: moment(value[1]).format("YYYY-MM-DD"),
      });
    }
  };

  const filterOnChange = (value) => {
    setFilterType(value);
  };

  return (
    <>
      <div className="dashboard-count-filter mb-24">
        <div className="row align-items-center">
          {filterType == 5 && (
            <div className="col-auto pe-0">
              <DateRangePicker
                onChange={(e) => onRangeChange(e)}
                value={selectedDate}
                format="M-d-y"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                calendarIcon={<i className="icon icon-calender"></i>}
                maxDate={new Date()}
                clearIcon={null}
              />
            </div>
          )}
          <div className="col-auto">
            <span className="page-subtitle">
              Total <b>{responseData?.total_offers_made}</b> Offer Made
            </span>
          </div>
          <div className="col-auto">
            <div className="form-group">
              <select
                className="form-select"
                onChange={(e) => filterOnChange(e.target.value)}
                defaultValue={filterType}>
                <option value="1">This day</option>
                <option value="2">This week</option>
                <option value="3">This month</option>
                <option value="4">This year</option>
                <option value="5">Range</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <Pie
            options={options}
            data={{
              labels,
              datasets: [
                {
                  label: "Appraisals",
                  data: [
                    responseData?.count?.accepted_offer,
                    responseData?.count?.no_deal,
                  ],
                  backgroundColor: ["#8bc34a", "#ff4343"],
                },
              ],
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ClosingReport;
