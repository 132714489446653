import React, { useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import routes from "../../constants/routesConstants";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import logo2Img from "../../assets/img/logo2.svg";
import usePermission from "../../hook/usePermission";
import {
  APPRAISALS_ALL_LIST,
  APPRAISALS_OWN_OR_ASSIGNED_LIST,
  BUSINESS_LIST,
  DASHBOARD_VIEW,
  EMPLOYEES_LIST,
  FAQ_LIST,
  INQUIRIES_LIST,
  REPORTS_LIST,
  ROLE_LIST,
  SCHEDULE_DEMO_LIST,
} from "../../constants/permissionConstants";

const Sidebar = () => {
  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);
  const location = useLocation();

  const isDashboardAllow = usePermission(DASHBOARD_VIEW);
  const isAppraisalAllow = usePermission(APPRAISALS_ALL_LIST);
  const isAppraisalOwnAllow = usePermission(APPRAISALS_OWN_OR_ASSIGNED_LIST);
  const isBusinessAllow = usePermission(BUSINESS_LIST);
  const isReportAllow = usePermission(REPORTS_LIST);
  const isEmployeeAllow = usePermission(EMPLOYEES_LIST);
  const isRoleAllow = usePermission(ROLE_LIST);
  const isInquiriesAllow = usePermission(INQUIRIES_LIST);
  const isFaqAllow = usePermission(FAQ_LIST);
  const isScheduleDemoListAllow = usePermission(SCHEDULE_DEMO_LIST);

  const logoutHandler = () => {
    authCtx.logout();
  };
  return (
    <div
      className={`dashboard-sidebar ${location.pathname === "/" ||
        location.pathname === "/privacy-policy" ||
        location.pathname === "/terms-condition"
        ? "d-flex d-lg-none"
        : ""
        } ${themeCtx.isSidebarOpen ? "show" : ""}`}>
      <div>
        <div className="dashboard-sidebar-logo d-block d-lg-none">
          <Link to={routes.Dashboard}>
            <img src={logo2Img} alt="" />
          </Link>
          <button
            type="button"
            className="dashboard-sidebar-close-btn"
            onClick={() => themeCtx.toggleSidebar()}>
            <i className="icon icon-close"></i>
          </button>
        </div>
        <ul>
          {authCtx.isLoggedIn && (
            <>
              {isDashboardAllow && (
                <li>
                  <NavLink to={routes.Dashboard}>
                    <i className="icon icon-dashboard"></i> Dashboard
                  </NavLink>
                </li>
              )}
              {isBusinessAllow && (
                <li>
                  <NavLink to={routes.Business}>
                    <i className="icon icon-total-appraisal"></i> Dealers
                  </NavLink>
                </li>
              )}
              {(isAppraisalAllow || isAppraisalOwnAllow) && (
                <li>
                  <NavLink to={routes.Appraisals}>
                    <i className="icon icon-appraisals"></i> Appraisals
                  </NavLink>
                </li>
              )}
              {isReportAllow && (
                <li>
                  <NavLink to={routes.Reports}>
                    <i className="icon icon-reports"></i> Reports
                  </NavLink>
                </li>
              )}
              {authCtx.userData?.user_type !== 2 &&
                authCtx.userData?.user_type !== 4 && (
                  <li>
                    <NavLink to={routes.Contest}>
                      <i className="icon icon-offer-made"></i> Contest
                    </NavLink>
                  </li>
                )}
              <li>
                <NavLink to={routes.Vehicles}>
                  <i className="icon icon-total-appraisal"></i> Wish List
                </NavLink>
              </li>
              {(isEmployeeAllow || isRoleAllow) && <hr />}
              {isEmployeeAllow && (
                <li>
                  <NavLink to={routes.Employees}>
                    <i className="icon icon-employees"></i> Employees
                  </NavLink>
                </li>
              )}
              {isRoleAllow && (
                <li>
                  <NavLink to={routes.Roles}>
                    <i className="icon icon-filter"></i> Roles
                  </NavLink>
                </li>
              )}
              {(authCtx.userData?.user_type === 0 ||
                authCtx.userData?.user_type === 1) && (
                  <li>
                    <NavLink to={routes.NotificationsPermissions}>
                      <i className="icon icon-notification"></i> Notif Pref
                    </NavLink>
                  </li>
                )}
              {(isInquiriesAllow || isFaqAllow) && <hr />}
              {isInquiriesAllow && (
                <li>
                  <NavLink to={routes.Inquiry}>
                    <i className="icon icon-chat"></i> Inquiries
                  </NavLink>
                </li>
              )}
              {isScheduleDemoListAllow && (
                <li>
                  <NavLink to={routes.ScheduleDemoList}>
                    <i className="icon icon-calender"></i> Demo Inquiries
                  </NavLink>
                </li>
              )}
              {isFaqAllow && (
                <li>
                  <NavLink to={routes.Faqs}>
                    <i className="icon icon-appraisals"></i> FAQs
                  </NavLink>
                </li>
              )}
              <hr className="d-xl-none d-block" />
            </>
          )}
          <li className="d-xl-none d-block">
            <button type="button" onClick={() => themeCtx.getHelpPopupOpen()}>
              Get Help
            </button>
          </li>
          <li className="d-xl-none d-block">
            <button type="button" onClick={() => themeCtx.contactPopupOpen()}>
              Contact Us
            </button>
          </li>
        </ul>
      </div>
      {authCtx.isLoggedIn && (
        <ul>
          <li>
            <button type="button" onClick={() => logoutHandler()}>
              <i className="icon icon-logout"></i> Logout
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Sidebar;
