import React, { useContext, useEffect, useState } from "react";
import api from "../../api";
import useDebounce from "../../hook/useDebounce";
import AuthContext from "../../context/authContext";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Swal from "../Swal";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../constants/routesConstants";
import BusinessContext from "../../context/businessContext";
import { formatCurrency } from "../../utils/formats";

const Contest = () => {
  const [responseData, setResponsedata] = useState({});
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 0,
    current: 0,
  });

  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const [search, setSearch] = useState("");
  const searchDebouncedValue = useDebounce(search, 1000);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      authCtx.userData?.user_type === 2 ||
      authCtx.userData?.user_type === 4
    ) {
      navigate(routes.Dashboard);
    } else if (
      authCtx.userData?.user_type === 0 ||
      authCtx.userData?.user_type === 1
    ) {
      navigate(`/contest/edit/${authCtx.userData?.business_id}`);
    }
  }, []);

  useEffect(() => {
    let filterData = {};
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    fetchContest(filterData, 1);
  }, [searchDebouncedValue]);

  async function fetchContest(formData = {}, page) {
    setLoading(true);
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    await api
      .post(`api/contest?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        fetchContest(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        fetchContest(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      fetchContest(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (searchDebouncedValue != "") {
      filterData = {
        ...filterData,
        search: searchDebouncedValue,
      };
    }
    fetchContest(filterData, e.selected + 1);
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/contest/delete",
              { contest_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                fetchContest({}, 1);
                Swal.fire(response.data.message, "", "success");
              } else {
                Swal.fire("Try Again!", response.data.message, "info");
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
        }
      });
    }
    return false;
  };

  const clearHandler = () => {
    setSearch("");
  };

  return (
    <>
      <div className="dashboard-count-filter dashboard-appraisals  mb-24">
        <div className="row align-items-center">
          <div className="col">
            <h2 className="page-title">Contest List</h2>
            <p className="page-dec">
              In total there is <b>{pagination?.total_records}</b> contest
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard-latest-appraisals">
        <div className="dashboard-card">
          <div className="dashboard-card-filter dashboard-count-filter">
            <div className="row align-items-center">
              <div className="col">
                <div className="dashboard-navbar-search dashboardml">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="dashboard-navbar-date-filter dashboardml">
                  <button
                    type="button"
                    className="date-filter"
                    onClick={() => clearHandler()}>
                    Clear <i className="icon icon-reset"></i>
                  </button>
                </div>
              </div>
              <div className="col-auto">
                <div className="dashboard-export">
                  <Link to={routes.ContestCreate} className="btn btn-pink">
                    <i className="icon icon-add"></i> Add Contest
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-latest-apraisals faq-table table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Business Name</th>
                  <th width="10%">
                    Start Date{" "}
                    <span
                      className={`table-sort ${
                        sortField == "start_date" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "start_date",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    End Date{" "}
                    <span
                      className={`table-sort ${
                        sortField == "end_date" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "end_date",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Per Appraisal Spiff{" "}
                    <span
                      className={`table-sort ${
                        sortField == "per_appraisal_spiff" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "per_appraisal_spiff",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th>
                    Per Accepted Appraisal Spiff{" "}
                    <span
                      className={`table-sort ${
                        sortField == "per_accepted_appraisal_spiff"
                          ? sortType
                          : ""
                      }`}
                      onClick={() =>
                        sort(
                          "per_accepted_appraisal_spiff",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th width="10%">
                    Created At{" "}
                    <span
                      className={`table-sort ${
                        sortField == "created_at" ? sortType : ""
                      }`}
                      onClick={() =>
                        sort(
                          "created_at",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }></span>
                  </th>
                  <th width="15%">Action</th>
                </tr>
              </thead>
              <tbody>
                {responseData?.contestData &&
                responseData?.contestData.length != 0 ? (
                  responseData?.contestData.map((data) => (
                    <tr key={data?.id}>
                      <td>{data?.id}</td>
                      <td>{data?.business_name}</td>
                      <td>{moment(data?.start_date).format("MMMM D, YYYY")}</td>
                      <td>{moment(data?.end_date).format("MMMM D, YYYY")}</td>
                      <td>{formatCurrency(data?.per_appraisal_spiff)}</td>
                      <td>
                        {formatCurrency(data?.per_accepted_appraisal_spiff)}
                      </td>
                      <td>{moment(data?.created_at).format("MMMM D, YYYY")}</td>
                      <td>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => deleteHandler(data?.id)}>
                          <i className="icon icon-delete"></i>
                        </button>
                        <Link
                          to={`/contest/edit/${data?.id}`}
                          className="btn-icon">
                          <i className="icon icon-edit"></i>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="text-start text-sm-center">
                    <td colSpan={6}>
                      {loading ? "Loading..." : "No Contest Found."}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {pagination?.total_pages != undefined && pagination?.total_pages != 1 && (
        <nav className="navigation pagination">
          <ReactPaginate
            breakLabel="..."
            onPageChange={onPaginationFetch}
            pageRangeDisplayed={2}
            pageCount={pagination?.total_pages}
            renderOnZeroPageCount={null}
            containerClassName="nav-links"
            activeLinkClassName="current"
            pageLinkClassName="page-numbers"
            previousLinkClassName="prev page-numbers"
            previousLabel={<i className="icon icon-arrow-left"></i>}
            nextLinkClassName="next page-numbers"
            nextLabel={<i className="icon icon-arrow-right"></i>}
          />
        </nav>
      )}
    </>
  );
};

export default Contest;
