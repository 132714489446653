import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authContext";
import api from "../../api";
import vehicleImg from "../../assets/img/vehicle.jpg";
import userImg from "../../assets/img/user.jpg";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import noAppraisalImg from "../../assets/img/no-appraisalimg.png";
import Swal from "../Swal";
import routes from "../../constants/routesConstants";
import { formatCurrency } from "../../utils/formats";
import BusinessContext from "../../context/businessContext";
import {
  APPRAISALS_DELETE,
  APPRAISALS_EDIT,
  APPRAISALS_VIEW,
} from "../../constants/permissionConstants";
import usePermission from "../../hook/usePermission";

const LatestAppraisals = ({ businessId, filterType, selectedDate }) => {
  const [responseData, setResponsedata] = useState({});
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const authCtx = useContext(AuthContext);
  const businessCtx = useContext(BusinessContext);
  const navigate = useNavigate();

  const isAppraisalEditAllow = usePermission(APPRAISALS_EDIT);
  const isAppraisalDeleteAllow = usePermission(APPRAISALS_DELETE);
  const isAppraisalViewAllow = usePermission(APPRAISALS_VIEW);

  useEffect(() => {
    let filterData = {};
    if (filterType != 5) {
      filterData = {
        ...filterData,
        lp_type: filterType,
      };
      getlatestAppraisals(filterData, 1);
    } else if (filterType == 5) {
      filterData = {
        ...filterData,
        lp_type: 4,
      };
      getlatestAppraisals(filterData, 1);
    }
    if (selectedDate != null && filterType == 5) {
      filterData = {
        ...filterData,
        lp_type: filterType,
        start_date_lp: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date_lp: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
      getlatestAppraisals(filterData, 1);
    }
  }, [filterType, selectedDate, businessCtx.selectedBusinessId]);

  const getlatestAppraisals = async (formData = {}, page) => {
    setLoading(true);
    if (
      authCtx.userData?.user_type == 3 &&
      businessCtx.selectedBusinessId != ""
    ) {
      formData.business_id = businessCtx.selectedBusinessId;
    }
    if (businessId !== undefined) {
      formData.business_id = businessId;
    }
    await api
      .post(`api/dashboard/latest-appraisals?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponsedata(response.data.data);
          setPagination(response.data.data.paginate);
        } else {
          setResponsedata([]);
          setPagination({
            total_records: 0,
            total_pages: 0,
            current: 0,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        } else {
          Swal.fire(
            "Opps!",
            "Sorry we are not able to complete your request at this time. Please try again later.",
            "error"
          );
        }
      });
  };

  function sort(field, currentField, sort, page) {
    let filterData = {};
    if (filterType != 5) {
      filterData = {
        ...filterData,
        lp_type: filterType,
      };
    } else if (filterType == 5) {
      filterData = {
        ...filterData,
        lp_type: 4,
      };
    }
    if (selectedDate != null && filterType == 5) {
      filterData = {
        ...filterData,
        lp_type: filterType,
        start_date_lp: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date_lp: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
        };
        getlatestAppraisals(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
        };
        getlatestAppraisals(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
      };
      getlatestAppraisals(filterData, page);
    }
  }

  const onPaginationFetch = (e) => {
    let filterData = {};
    if (filterType != 5) {
      filterData = {
        ...filterData,
        lp_type: filterType,
      };
    } else if (filterType == 5) {
      filterData = {
        ...filterData,
        lp_type: 4,
      };
    }
    if (sortField != "" && sortType != "") {
      filterData = {
        ...filterData,
        field_name: sortField,
        order_by: sortType,
      };
    }
    if (selectedDate != null && filterType == 5) {
      filterData = {
        ...filterData,
        lp_type: filterType,
        start_date_lp: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date_lp: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    getlatestAppraisals(filterData, e.selected + 1);
  };

  const deleteHandler = (id) => {
    if (id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          api
            .post(
              "api/leads/delete-lead",
              { lead_id: id },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData?.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                let filterData = {};
                if (filterType != 5) {
                  filterData = {
                    ...filterData,
                    lp_type: filterType,
                  };
                  getlatestAppraisals(filterData, 1);
                }
                if (selectedDate != null && filterType == 5) {
                  filterData = {
                    ...filterData,
                    lp_type: filterType,
                    start_date_lp: moment(selectedDate[0]).format("YYYY-MM-DD"),
                    end_date_lp: moment(selectedDate[1]).format("YYYY-MM-DD"),
                  };
                  getlatestAppraisals(filterData, 1);
                }
                Swal.fire("Deleted!", response.data.message, "success");
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              } else {
                Swal.fire(
                  "Opps!",
                  "Sorry we are not able to complete your request at this time. Please try again later.",
                  "error"
                );
              }
            });
        }
      });
    }
    return false;
  };

  const redirectToEdit = (id) => {
    if (id && (isAppraisalEditAllow || isAppraisalViewAllow)) {
      navigate(`/appraisals/view/${id}`);
    }
  };

  return (
    <>
      {authCtx.userData?.user_type == 2 &&
      responseData?.latestAppraisals &&
      responseData?.latestAppraisals.length == 0 ? (
        <div className="no-appraisalMain text-center">
          <div className="no-appraisalimg">
            <img src={noAppraisalImg} alt="" />
          </div>
          <div className="secHead text-center">
            <h4 className="secTitle size24">No Appraisal yet!</h4>
            <p>Start adding appraisals, it will be displayed here. </p>
          </div>
          <Link to={routes.AppraisalCreate} className="btn btn-pink">
            <i className="icon icon-add"></i> Add New Appraisal
          </Link>
        </div>
      ) : (
        <div className="dashboard-latest-appraisals with-cursor">
          <div className="dashboard-card">
            <div className="dashboard-card-filter">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="card-title">Latest Appraisals</h4>
                </div>
              </div>
            </div>
            <div className="dashboard-latest-apraisals table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    {authCtx.userData?.user_type == 3 && (
                      <th>
                        Business Name{" "}
                        <span
                          className={`table-sort ${
                            sortField == "businessName" ? sortType : ""
                          }`}
                          onClick={() =>
                            sort(
                              "businessName",
                              sortField,
                              sortType,
                              pagination.current
                            )
                          }></span>
                      </th>
                    )}
                    <th width="25%">
                      Vehicle{" "}
                      <span
                        className={`table-sort ${
                          sortField == "model" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort("model", sortField, sortType, pagination.current)
                        }></span>
                    </th>
                    <th width="10%">
                      Customer{" "}
                      <span
                        className={`table-sort ${
                          sortField == "first_name" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "first_name",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }></span>
                    </th>
                    <th width="11%">
                      Status{" "}
                      <span
                        className={`table-sort ${
                          sortField == "status" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "status",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }></span>
                    </th>
                    <th width="10%">
                      Offer{" "}
                      <span
                        className={`table-sort ${
                          sortField == "deals_price" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "deals_price",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }></span>
                    </th>
                    <th width="12%">
                      Submitted Date{" "}
                      <span
                        className={`table-sort ${
                          sortField == "created_at" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "created_at",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }></span>
                    </th>
                    <th width="10%">
                      Assign To{" "}
                      <span
                        className={`table-sort ${
                          sortField == "assign_name" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort(
                            "assign_name",
                            sortField,
                            sortType,
                            pagination.current
                          )
                        }></span>
                    </th>
                    <th width="15%">
                      Added by{" "}
                      <span
                        className={`table-sort ${
                          sortField == "name" ? sortType : ""
                        }`}
                        onClick={() =>
                          sort("name", sortField, sortType, pagination.current)
                        }></span>
                    </th>
                    {(isAppraisalEditAllow ||
                      isAppraisalDeleteAllow ||
                      isAppraisalViewAllow) && <th width="10%">Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {responseData?.latestAppraisals &&
                  responseData?.latestAppraisals.length != 0 ? (
                    responseData?.latestAppraisals.map((data) => (
                      <tr key={data?.id}>
                        <td width="3%" onClick={() => redirectToEdit(data?.id)}>
                          {authCtx.userData?.user_type == 3
                            ? data?.id
                            : data?.sequence_id}
                        </td>
                        {authCtx.userData?.user_type == 3 && (
                          <td onClick={() => redirectToEdit(data?.id)}>
                            {data?.businessName}
                          </td>
                        )}
                        <td onClick={() => redirectToEdit(data?.id)}>
                          <div className="table-vehicle-image">
                            <img
                              src={
                                data?.vehicleImage != ""
                                  ? data?.vehicleImage
                                  : vehicleImg
                              }
                              alt=""
                            />
                          </div>
                          {data?.year} {data?.make} {data?.model}
                        </td>
                        <td onClick={() => redirectToEdit(data?.id)}>
                          {data?.first_name} {data?.last_name}
                        </td>
                        <td onClick={() => redirectToEdit(data?.id)}>
                          {data?.status == 0 && (
                            <span className="status-new">New</span>
                          )}
                          {data?.status == 1 && (
                            <span className="status-working">Working</span>
                          )}
                          {data?.status == 2 && (
                            <span className="status-offermade">Offer Made</span>
                          )}
                          {data?.status == 3 && (
                            <span className="status-accepted-purchase">
                              Accepted Offer
                            </span>
                          )}
                          {data?.status == 4 && (
                            <span className="status-appraised">Appraised</span>
                          )}
                          {data?.status == 5 && (
                            <span className="status-no-deal">No Deal</span>
                          )}
                          {data?.status == 6 && (
                            <span className="status-counteroffer">
                              Counter Offer Request
                            </span>
                          )}
                        </td>
                        <td onClick={() => redirectToEdit(data?.id)}>
                          {data?.deals_price != null
                            ? formatCurrency(data?.deals_price)
                            : "No Offer"}
                        </td>
                        <td onClick={() => redirectToEdit(data?.id)}>
                          {moment(data?.created_at).format("MMMM D, YYYY")}
                        </td>
                        <td
                          className="mxw-150"
                          onClick={() => redirectToEdit(data?.id)}>
                          {data?.assign_name !== null &&
                          data?.assign_name !== ""
                            ? data?.assign_name
                            : "Not Assigned"}
                        </td>
                        <td
                          className="mxw-150"
                          onClick={() => redirectToEdit(data?.id)}>
                          <div className="table-user-image">
                            <img
                              src={data?.image != "" ? data?.image : userImg}
                              alt=""
                            />
                          </div>
                          {data?.name}
                        </td>
                        {(isAppraisalEditAllow ||
                          isAppraisalDeleteAllow ||
                          isAppraisalViewAllow) && (
                          <td>
                            {isAppraisalDeleteAllow && data?.status != 3 && (
                              <button
                                type="button"
                                className="btn-icon"
                                onClick={() => deleteHandler(data?.id)}>
                                <i className="icon icon-delete"></i>
                              </button>
                            )}
                            {(isAppraisalEditAllow || isAppraisalViewAllow) && (
                              <Link
                                to={`/appraisals/view/${data?.id}`}
                                className="btn-icon">
                                {isAppraisalViewAllow &&
                                !isAppraisalEditAllow ? (
                                  <i className="icon icon-eye-open"></i>
                                ) : (
                                  <i className="icon icon-edit"></i>
                                )}
                              </Link>
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr className="text-start text-sm-center">
                      <td colSpan={9}>
                        {loading ? "Loading..." : "No Appraisal Found."}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {pagination?.total_pages != undefined &&
            pagination?.total_pages != 1 && (
              <nav className="navigation pagination">
                <ReactPaginate
                  breakLabel="..."
                  onPageChange={onPaginationFetch}
                  pageRangeDisplayed={2}
                  forcePage={parseInt(pagination?.current - 1)}
                  pageCount={pagination?.total_pages}
                  renderOnZeroPageCount={null}
                  containerClassName="nav-links"
                  activeLinkClassName="current"
                  pageLinkClassName="page-numbers"
                  previousLinkClassName="prev page-numbers"
                  previousLabel={<i className="icon icon-arrow-left"></i>}
                  nextLinkClassName="next page-numbers"
                  nextLabel={<i className="icon icon-arrow-right"></i>}
                />
              </nav>
            )}
        </div>
      )}
    </>
  );
};

export default LatestAppraisals;
